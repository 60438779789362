type User = {
  name: string
  phone: string
}
type TCountry = {
  id: number
  name: string
  url: string
  description: string
  start_work: string
  end_work: string
  order_price_min: number
  delivery_commission: number
  whatsapp_phone: string
  is_some_area: number
  slug: string
}

interface Store {
  user: User | null
  basket: any[] | null
  contacts: any[] | null
  is_country: boolean
  current_county: TCountry | null
  countries: TCountry[]
  carouselItems: any[]
}

export const useIndexStore = defineStore({
  id: 'index',
  state: (): Store => ({
    user: null,
    basket: [],
    contacts: [],
    is_country: false,
    current_county: null,
    countries: [],
    carouselItems: [],
  }),
  getters: {
    countries_list(state) {
      return state.countries
    },
    country(state) {
      return state.current_county
    },
    slug(state) {
      return state.current_county?.slug
    },
  },
  actions: {
    getCarouselItems() {
      const city = JSON.parse(localStorage.getItem('city') || '{}')
      if (city) {
        useApi()
          .$get('/carousel', {
            params: {
              city_id: city.id,
            },
          })
          .then((res) => {
            this.carouselItems = res
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async fetchCountries() {
      return await useApi()
        .$get('/cities')
        .then((res) => {
          this.countries = res
          this.checkAndSetCountry()

          return res
        })
    },
    setCountry(payload: any) {
      localStorage.setItem('city', JSON.stringify(payload))
      this.current_county = payload
    },
    getCountry() {
      if (process.client) {
        const country = localStorage.getItem('city') || null
        if (country) {
          this.current_county = JSON.parse(localStorage.getItem('city') || '{}')
          this.is_country = true
          return { ...this.current_county }
        }
      }
    },
    checkAndSetCountry() {
      const route = useRoute()
      if (
        JSON.parse(localStorage?.getItem('city') || '{}')?.slug !==
        route.fullPath.split('/')[2]
      ) {
        const foundObject = this.countries.find(
          (item) => item.slug === route.fullPath.split('/')[2]
        )
        if (foundObject) {
          this.current_county = foundObject
          localStorage.setItem('city', JSON.stringify(foundObject))
          location.reload()
        }
      } else if (
        route.fullPath.split('/')[2] &&
        !localStorage.getItem('city')
      ) {
        const foundObject = this.countries.find(
          (item) => item.slug === route.fullPath.split('/')[2]
        )
        if (foundObject) {
          this.current_county = foundObject
          localStorage.setItem('city', JSON.stringify(foundObject))
        }
      }
    },
    async fetchContacts() {
      await useApi()
        .$get('/contacts')
        .then((res) => {
          this.contacts = res

          return res
        })
    },
    async sendFeedback(payload) {
      await useApi()
        .$post('/feedback', payload)
        .then((res) => {
          return res
        })
    },
    async sendResume(payload) {
      await useApi()
        .$post('/vacancy/resume', payload)
        .then((res) => {
          return res
        })
    },
  },
})
